<template>
  <div
    v-if="!loadingDetailWorkflow && workflowDetail.action && workflowDetail.created_by"
  >
    <app-breadcrumb
      title="Detail Workflow"
      hide-breadcrumbs
      :hide-title="$vuetify.breakpoint.smAndDown"
    />
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h4>
              Informasi Workflow
            </h4>
          </v-card-title>
          <v-card-text>
            <div class="text--disabled">
              Name
            </div>
            <p>{{ workflowDetail.name }}</p>
            <div class="text--disabled">
              Dibuat oleh
            </div>
            <v-tooltip
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <router-link
                  :to="{
                    name: 'admin-user-detail',
                    params: {
                      id: workflowDetail.created_by.id,
                    },
                  }"
                >
                  <v-avatar
                    v-bind="attrs"
                    class="v-avatar-light-bg"
                    size="36"
                    v-on="on"
                  >
                    <v-img
                      v-if="workflowDetail.created_by.photo"
                      :src="workflowDetail.created_by.photo"
                    />
                    <span v-else>{{ avatarText(workflowDetail.created_by.name) }}</span>
                  </v-avatar>
                </router-link>
              </template>
              <span>{{ workflowDetail.created_by.name }}</span>
            </v-tooltip>
            <span class="ms-2">{{ workflowDetail.created_by.name }}</span>

            <div class="text--disabled mt-4">
              Dibuat pada
            </div>
            <p>{{ formatDate(workflowDetail.created_at) }}</p>
            <div class="text--disabled mt-4">
              Trigger
            </div>
            <span>{{ workflowDetail.action.object.name }}</span>
            <v-icon
              size="16"
              class="mx-2"
            >
              {{ icons.mdiArrowRight }}
            </v-icon>
            <span>{{ workflowDetail.action.name_view }}</span>
            <div class="text--disabled mt-4">
              Executor
            </div>
            <div
              v-for="executor in workflowDetail.executors"
              :key="executor.id"
            >
              <span>{{ executor.action.object.name }}</span>
              <v-icon
                size="16"
                class="mx-2"
              >
                {{ icons.mdiArrowRight }}
              </v-icon>
              <span>{{ executor.action.name_view }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="9"
      >
        <v-card>
          <v-card-title class="d-flex justify-center">
            <h4>
              Riwayat Automasi
            </h4>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="text-caption-2 mb-1">
                  Filter Tanggal
                </div>
                <DateRangePicker
                  ref="dateRangePicker"
                  v-model="filterDate"
                  class="mb-4"
                  :max-date="today"
                  @update="changeFilterDate"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <div class="text-caption-2 mb-1">
                  Filter Status
                </div>
                <v-select
                  v-model="filterLog.status"
                  :items="statusOptions"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  flat
                  hide-details="auto"
                  @change="loadWorkflowLog"
                />
              </v-col>
            </v-row>
            <v-chip
              v-if="filterLog.status !== null || filterLog.date_min || filterLog.date_max"
              class="mb-4"
              small
              close
              color="error"
              @click:close="resetFilter"
            >
              Reset Filter
            </v-chip>
            <v-data-table
              item-key="id"
              height="53vh"
              fixed-header
              class="mx-5 my-5"
              :headers="tableColumns"
              :items="workflowLogList"
              mobile-breakpoint="0"
              :server-items-length="workflowLogCount"
              disable-sort
              :footer-props="{
                'items-per-page-options': [10, 25, 50],
                'items-per-page-text': 'Jumlah data',
              }"
              :options.sync="paginationLog"
              :loading="loadingLogWorkflow"
            >
              <template v-slot:[`item.executed_at`]="props">
                {{ formatDate(props.item.executed_at, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }) }}
              </template>
              <template v-slot:[`item.action`]="props">
                <span>{{ props.item.object_name }}</span>
                <v-icon
                  size="16"
                  class="mx-2"
                >
                  {{ icons.mdiArrowRight }}
                </v-icon>
                <span>{{ props.item.action_name }}</span>
              </template>
              <template v-slot:[`item.status`]="props">
                <v-chip
                  :color="props.item.is_error ? 'error' : 'success'"
                  outlined
                >
                  {{ props.item.is_error ? 'Error' : 'Success' }}
                </v-chip>
              </template>
              <template v-slot:[`item.message`]="props">
                <span>{{ props.item.message ? props.item.message : '-' }}</span>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import useAutomationV2 from '@/composables/useAutomation'
import { onMounted, ref, watch } from '@vue/composition-api'
import { mdiArrowRight } from '@mdi/js'
import AppBreadcrumb from '@/components/misc/AppBreadcrumb.vue'
import { avatarText, formatDate } from '@core/utils/filter'
import DateRangePicker from '@/components/inputs/DateRangePicker.vue'

export default {
  components: {
    AppBreadcrumb,
    DateRangePicker,
  },
  props: {
    id: {
      type: [String, Number],
      default: null,
      required: true,
    },
  },
  setup(props) {
    const {
      fetchWorkflowDetailAdmin, loadingDetailWorkflow, workflowDetail,
      fetchWorkflowLog, loadingLogWorkflow, workflowLogList, workflowLogCount,
    } = useAutomationV2()
    const today = new Date()
    const dateRangePicker = ref(null)
    const filterDate = { startDate: null, endDate: null }
    const filterLog = ref({
      sort: 'executed_at', sort_type: 'DESC', trigger_id: +props.id, date_min: null, date_max: null, status: null,
    })
    const paginationLog = ref({})
    const tableColumns = ref([
      {
        text: 'Tanggal',
        value: 'executed_at',
      },
      {
        text: 'Aksi',
        value: 'action',
      },
      {
        text: 'Status',
        value: 'status',
      },
      {
        text: 'Keterangan',
        value: 'message',
      },
    ])

    const statusOptions = ref([
      { label: 'Semua', value: null },
      { label: 'Success', value: false },
      { label: 'Error', value: true },
    ])

    const loadWorkflowLog = () => {
      fetchWorkflowLog({
        limit: paginationLog.value.itemsPerPage ? paginationLog.value.itemsPerPage : 10,
        offset: paginationLog.value.page ? (paginationLog.value.page - 1) * paginationLog.value.itemsPerPage : 0,
      }, filterLog.value, true)
    }

    onMounted(() => {
      fetchWorkflowDetailAdmin(+props.id).then(() => {
        console.log(workflowDetail)
      })
      loadWorkflowLog()
    })

    const changeFilterDate = val => {
      filterLog.value = {
        ...filterLog.value,
        date_min: val.startDate ? val.startDate.toISOString() : null,
        date_max: val.endDate ? val.endDate.toISOString() : null,
      }
      loadWorkflowLog()
    }

    const resetFilter = () => {
      filterLog.value = {
        sort: 'executed_at', sort_type: 'DESC', trigger_id: +props.id, date_min: null, date_max: null, status: null,
      }
      filterDate.value = { startDate: null, endDate: null }
      dateRangePicker.value.reset()
      loadWorkflowLog()
    }

    watch(paginationLog, () => {
      console.log(paginationLog.value)
      loadWorkflowLog()
    })

    // const changePagination = val => {
    //   console.log('tes')
    //   paginationLog.value = {
    //     limit: val.itemsPerPage,
    //     offset: (val.page - 1) * val.itemsPerPage,
    //   }
    //   fetchWorkflowLog(paginationLog.value, filterLog.value)
    // }

    return {
      loadingDetailWorkflow,
      workflowDetail,
      icons: {
        mdiArrowRight,
      },
      avatarText,
      formatDate,
      tableColumns,
      loadingLogWorkflow,
      workflowLogList,
      paginationLog,
      workflowLogCount,
      filterLog,
      filterDate,
      statusOptions,
      loadWorkflowLog,
      changeFilterDate,
      resetFilter,
      dateRangePicker,
      today,
    }
  },
}
</script>
<style>

</style>
